<template>
    <div class="mb-50">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    v-if="!!previousRoute.value"
                    :to="{ path: previousRoute.value }"
                    >{{ previousRoute.label }}</el-breadcrumb-item
                >
                <el-breadcrumb-item>企业详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <detail-page
            v-loading="loadingStatus"
            v-if="showDetail"
            :cards="cards"
            :title="title"
            :actions="actions"
            @action="onAction"
        />
        <div class="more-detail" v-if="!!dataSourceUrl">
            <el-link v-if="!emptyInfo"
                :disabled="loadingStatus"
                class="title"
                :underline="false"
                @click="viewMoreDetail()"
                >查看更多详情
                <el-image
                    :src="require('../../assets/images/bjtsy/11941.png')"
                ></el-image>
            </el-link>
            <span v-else>未获取到企业备案详情数据</span>
        </div>
    </div>
</template>

<script>
import DetailPage from "@/components/detail-page";
import { getEntRecordDetail } from "@/api/aptitude";

export default {
    components: {
        DetailPage,
    },
    data() {
        return {
            loadingStatus: false,
            dataSourceUrl: "",
            emptyInfo: false,
            previousRoute: {
                label: "",
                value: "",
            },
            showDetail: true,
            cards: [],
            actions: [],
            title: "",
            creditCode: "",
            fromProvince: "",
            enterpriseName: "",
        };
    },
    mounted() {
        this.extractPreviousRoute();
        this.extractUnicode();
        this.getDetail();
    },
    methods: {
        extractPreviousRoute() {
            const matched = this.$route.matched;
            if (matched.length > 1) {
                const previousRoute = matched[matched.length - 2];
                const previousRouteName = previousRoute.name;
                console.log(
                    "extractPreviousRoute-->previousRouteName=" +
                        previousRouteName
                );
                let label = "",
                    value = "";
                if (previousRouteName == "record") {
                    label = "备案查询";
                    value = "/" + previousRouteName + "/index";
                }
                this.previousRoute.label = label;
                this.previousRoute.value = value;
            }
        },
        routeToQualification() {
            this.$router.push({path: "/qualification/detail/", query: { unicode: this.creditCode }});
        },
        routeToCreditScore() {
            this.$router.push({name: "creditscore-index", params: { fromProvince: this.fromProvince, creditCode: this.creditCode }});
        },
        routeToProject() {
            this.$router.push({name: "project-index", params: { buildcorpname: this.enterpriseName }});
        },
        onAction(actValue) {
            console.log("onAction-->" + actValue);
            if (actValue == "qualification") {
                this.routeToQualification();
            } else if (actValue == 'creditscore') {
                this.routeToCreditScore();
            } else if (actValue == 'project') {
                this.routeToProject();
            }
        },
        viewMoreDetail() {
            // 跳转外链
            console.log("viewMoreDetail...creditCode=" + this.creditCode);
            window.open(this.dataSourceUrl, '_blank');
        },
        extractUnicode() {
            const creditCode = this.$route.query.creditCode;
            const fromProvince = this.$route.query.fromProvince;
            this.creditCode = creditCode;
            this.fromProvince = fromProvince;
        },
        createFujianEntCard(item) {
            let card = {
                colsNum: 2,
                title: item.enterpriseName,
                fields: [
                    {
                        label: "企业曾用名",
                        value: item.previousName,
                    },
                    {
                        label: "企业注册地址",
                        value: item.registeredAddress,
                    },
                    {
                        label: "企业详细地址",
                        value: item.detailedAddress,
                    },
                    {
                        label: "统一社会信用编码/组织机构代码",
                        value: item.creditCode,
                    },
                    {
                        label: "成立时间",
                        value: item.establishTime,
                    },
                    {
                        label: "企业法定代表人",
                        value: item.legalPerson,
                    },
                    {
                        label: "注册资本(万元)",
                        value: item.registeredCapital,
                    },
                    {
                        label: "",
                        value: "",
                    },
                ],
            };
            return card;
        },
        createGeneralEntCard(item) {
            let provinceCityCounty =
                item.province +
                (item.city ? "-" + item.city : "") +
                (item.county ? "-" + item.county : "");
            let qualificationType =
                item.qualificationType && Array.isArray(item.qualificationType)
                    ? item.qualificationType.join(",")
                    : item.qualificationType;
            let card = {
                colsNum: 2,
                title: item.enterpriseName,
                fields: [
                    {
                        label: "统一社会信用代码",
                        value: item.creditCode,
                    },
                    {
                        label: "企业法定代表人",
                        value: item.legalPerson,
                    },
                    {
                        label: "企业登记注册类型",
                        value: qualificationType,
                    },
                    {
                        label: "企业注册属地",
                        value: provinceCityCounty,
                    },
                    {
                        label: "企业经营地址",
                        value: item.detailedAddress,
                    },
                ],
            };
            return card;
        },
        async getDetail() {
            if (this.loadingStatus) return;
            this.loadingStatus = true;
            try {
                const creditCode = this.creditCode;
                const fromProvince = this.fromProvince;
                console.log(
                    `creditCode: ${creditCode}, fromProvince: ${fromProvince}`
                );
                const res = await getEntRecordDetail({
                    fromProvince: fromProvince,
                    creditCode: creditCode,
                });
                const item = res ? res.data : {};
                if (item) {
                    this.title = `企业详情 - ${item.enterpriseName}`;
                    this.dataSourceUrl = item.dataSourceUrl;
                    this.enterpriseName = item.enterpriseName;
                    this.cards = [ item.fromProvince == '福建省' ? this.createFujianEntCard(item) : this.createGeneralEntCard(item) ];
                    this.actions = [
                        { label: "中标项目", value: "project" },
                        { label: "全国信用分", value: "creditscore" },
                        { label: "企业资质", value: "qualification" },
                    ];
                    this.emptyInfo = false;
                } else {
                    this.emptyInfo = true;
                }
                this.showDetail = true; // 显示 DetailPage 组件
            } catch (error) {
                console.error(error);
                this.$message.error("获取企业信息失败");
            } finally {
                this.loadingStatus = false;
            }
        },
    },
};
</script>
<style scoped>
.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.fs-18 {
    font-size: 18px;
}
.mb-50 {
    margin-bottom: 50px;
}
.table-header-area {
    display: flex;
}
.table-header {
    width: 520px;
    height: 60px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 20px;
}

.table-title {
    width: 233px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    line-height: 60px;
}

.right-header {
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-right: 20px;
    flex: 1;
}

::v-deep .el-tabs__nav.is-stretch > * {
    width: auto;
}
::v-deep .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0px 0 30px;
}
::v-deep .detail-card {
    overflow: hidden;
    margin-bottom: 30px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    flex: 1;
    text-align: center;
    width: auto;
    height: 48px;
    font-size: 20px;
    line-height: 48px;
}
::v-deep .detail-card-label {
    display: table-cell;
    width: 232px;
    font-size: 20px;
    color: #888888;
    padding-top: 16px;
    padding-bottom: 16px;
}
::v-deep .detail-card-value {
    padding-bottom: 15px;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
    font-size: 18px;
}
::v-deep .detail-card-value {
    font-size: 20px;
}
::v-deep .detail-card-title {
    margin-bottom: 20px;
}
::v-deep .detail-card-divider {
    margin-bottom: 30px;
}

.more-detail {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 16px;
    background: #edf4ff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}

.more-detail > .title {
    width: auto;
    height: 18px;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 400;
    color: #1890ff;
    line-height: 0px;
}
</style>
